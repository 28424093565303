/* eslint-disable react/prop-types */
import React from 'react';
import { Typography } from '@mui/material';

export const DataCell = ({ column, ...props }) => {
	const value = props.getValue();
	return (
		<Typography
			title={
				column?.title ? column.title : typeof value === 'object' ? null : value
			}
			className={props.className ? props.className : ''}
			component={column?.component || 'p'}
			sx={{
				display: 'inline-block',
				fontSize: 'inherit',
				textOverflow: 'ellipsis',
				overflow: 'hidden',
				whiteSpace: column.wrap ? 'auto' : 'nowrap',
				...(column.aligned && { textAlign: column.aligned }),
				...(column.size && { width: column.size }),
				...(column.maxSize && { maxWidth: column.maxSize }),
				...(column.minSize && { minWidth: column.minSize }),
			}}
		>
			{value === 'Czech Republic' ? 'Czechia' : value}
		</Typography>
	);
};
