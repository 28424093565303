import { Link, LinkProps } from 'react-router-dom';
import { styled } from '@mui/material';
import { COLORS } from 'utils/consts';

interface CustomLinkProps extends LinkProps {
	isActive?: boolean;
}

export const StyledLink = styled(Link)<CustomLinkProps>(({ disabled, color, isActive }: any) => ({
	color: disabled 
		? '#ccc' 
		: isActive 
			? COLORS.font 
			: color || 'unset',
	pointerEvents: disabled || isActive ? 'none' : 'unset',
	textDecoration: 'none',
	display: 'flex',
	alignItems: 'center',
	gap: '6px',
	// cursor: isActive ? 
	'& > .MuiTypography-root': {
		color: disabled 
			? '#ccc' 
			: isActive 
				? COLORS.font 
				: color || 'unset',
	},
}));

export const StyledInlineLink = styled(Link)<CustomLinkProps>(
	({ disabled, color, isActive, style }: any) => ({
		color: disabled 
			? '#ccc' 
			: isActive 
				? COLORS.font 
				: color || 'unset',
		pointerEvents: disabled ? 'none' : 'unset',
		textDecoration: 'none',
		display: 'inline-flex',
		alignItems: 'center',
		'& > .MuiTypography-root': {
			color: disabled 
				? '#ccc' 
				: isActive 
					? COLORS.font 
					: color || 'unset',
		},
		...style,
	})
);

export const StyledBtnLink = styled(Link)(({ disabled, color }: any) => ({
	backgroundColor: disabled ? '#ccc' : color || COLORS.primary,
	color: disabled ? '#000' : COLORS.white,
	pointerEvents: disabled ? 'none' : 'unset',
	textDecoration: 'none',
	borderRadius: '10px',
	padding: '15px 60px',
	textAlign: 'center',
}));