import React, { FC, ReactNode, useEffect, useState, useMemo } from 'react';

import { Box, ListItem, Checkbox, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

import { MatFormLabel, TextFieldWrapper } from '..';
import { MaterialUISizeEnum } from 'models/enums';
import {
	MEDIUM_BORDER_RADIUS,
	SMALL_VARIANT_FONT_SIZE,
	SMALL_VARIANT_INPUT_HEIGHT,
} from 'utils/consts/themeConsts';
import { COLORS } from 'utils/consts';
import { getFlagEmoji } from 'utils';
import {
	MaterialUIInputSizeType,
	MaterialUIInputVariantType,
} from 'models/types';
import { useDebounce } from 'hooks';
import { useReferenceData } from 'context';

interface CountiesAutoCompleteInputInterface {
	onChange?: (x: any) => void;
	onBlur?: (x: any) => void;
	value?: any;
	data?: any[];
	name: string;
	label: string;
	labelInfo?: ReactNode;
	placeholder?: string;
	autoComplete?: string;
	variant?: MaterialUIInputVariantType;
	autoWidth?: boolean;
	fullWidth?: boolean;
	error?: boolean;
	helperText?: string;
	sx?: any;
	inputProps?: any;
	size?: MaterialUIInputSizeType;
}

const euCountriesName = 'EU27 (27 countries)';
const euCountriesCode = 'EU';
const euCountriesId = 999;

export const CountiesAutoCompleteInput: FC<CountiesAutoCompleteInputInterface> = ({
	value,
	onChange,
	label,
	labelInfo,
	placeholder,
	name,
	fullWidth,
	size = 'small',
}) => {
	const {
		referenceData: { countries },
	} = useReferenceData();
	const countriesWithEu = useMemo(() => {
		if (countries && countries.length) {
			return [
				{ name: euCountriesName, code: euCountriesCode, id: euCountriesId },
				...countries,
			];
		}
		return countries;
	}, [countries]);

	const euCountries = useMemo(() => {
		return countriesWithEu.filter(
			(val) =>
				val.name !== 'Albania' &&
				val.name !== 'Australia' &&
				val.name !== 'Switzerland' &&
				val.name !== 'UK' &&
				val.name !== 'United Kingdom' &&
				val.name !== 'Moldova' &&
				val.name !== 'North Macedonia' &&
				val.name !== 'Montenegro' &&
				val.name !== 'Serbia' &&
				val.name !== 'Turkiye' &&
				val.name !== 'Ukraine' &&
				val.name !== 'Kosovo' &&
				val.name !== 'Turkey' &&
				val.name !== 'Canada' &&
				val.name !== 'UK (Northern Ireland)'
		);
	}, [countriesWithEu]);

	const [inputOptions, setInputOptions] = useState<
		{ id: number; name: string; code: string }[]
	>([]);
	const [search, setSearch] = useState('');
	const debounceValue = useDebounce(search);
	const [open, setOpen] = useState(false);

	useEffect(() => {
		function getData(debounceValue) {
			const data = countriesWithEu.filter((country) =>
				country?.name?.toLowerCase().includes(debounceValue?.toLowerCase())
			);
			setInputOptions(data);
		}

		getData(debounceValue);
	}, [debounceValue, countriesWithEu]);

	const onChangeHandler = (index: number) => {
		const dataItem = inputOptions[index];

		let selectedCountries = value;
		if (
			dataItem.id === euCountriesId &&
			selectedCountries.some((country) => country.id === dataItem.id)
		) {
			// CASE 1 USER CLICKES ON "EU27 (27 countries)" OPTION AND OPTION IS SELECTED ALREADY ===> REMOVES ALL EU COUNTRUES
			selectedCountries = selectedCountries.filter(
				(val) => !euCountries.some((country) => country.id === val.id)
			);
		} else if (dataItem.id === euCountriesId) {
			// CASE 2 USER CLICKES ON "EU27 (27 countries)" OPTION AND OPTION IS NOT SELECTED ALREADY ===> ADDS ALL EU COUNTRUES
			selectedCountries = [...new Set([...selectedCountries, ...euCountries])];
		} else if (
			euCountries.some((country) => country.id === dataItem.id) &&
			selectedCountries.some((country) => country.id === dataItem.id)
		) {
			// CASE 3 USER CLICKES ON "Eu Country" OPTION AND "EU27 (27 countries)" IS SELECTED ALREADY ===> REMOVES "EU27 (27 countries)" AND THE SELECTED COUNTRY
			selectedCountries = selectedCountries.filter(
				(country) =>
					!(country.id === dataItem.id || country.id === euCountriesId)
			);
		} else if (value.some((country) => country.id === dataItem.id)) {
			// CASE 4 USER CLICKES ON ITEM THAT IS SELECTED ===> REMOVES THE SELECTED COUNTRY
			selectedCountries = value.filter((country) => country.id !== dataItem.id);
		} else {
			// CASE 5 USER CLICKES ON ITEM THAT IS NOT SELECTED ===> ADDS THE SELECTED COUNTRY
			selectedCountries = [...value, dataItem];
		}

		onChange && onChange({ target: { name, value: selectedCountries } });
	};

	const onClearHandler = (e, value) => {
		// CLEAR OPTION  ==> CLEARS THE SELECTED OPTIONS
		if (value.length === 0)
			onChange && onChange({ target: { name, value: [] } });
	};

	const onSearchChange = (e) => setSearch(e.target.value ?? '');

	return (
		<Box>
			<MatFormLabel id={name}>
				{label}
				{labelInfo && <Box sx={{ ml: 'auto' }}>{labelInfo}</Box>}
			</MatFormLabel>
			<Autocomplete
				multiple
				freeSolo
				open={open}
				onOpen={() => setOpen(true)}
				// onClose={() => setOpen(false)}
				onBlur={() => {setOpen(false); setSearch('');}}
				onKeyDown={(e) => {
					if (e.code === 'Escape') {
						setOpen(false);
					}
				}}
				fullWidth={fullWidth}
				size={size}
				inputValue={search}
				onInputChange={onSearchChange}
				value={value}
				onChange={onClearHandler}
				options={inputOptions}
				getOptionLabel={(option: any) => option.name}
				renderTags={() => null}
				renderOption={(props, option: any, rest) => (
					<ListItem
						key={option.id}
						value={option.name}
						onClick={() => onChangeHandler(rest.index)}
					>
						<Box display='flex' alignItems='center'>
							<Checkbox
								size={MaterialUISizeEnum.SMALL}
								checked={value.some((val) => val.id === option.id)}
							/>
							<Box mr={1}>{getFlagEmoji(option.code)}</Box>
							<Typography variant='body1' sx={{ fontSize: '14px' }}>
								{option.name}
							</Typography>
						</Box>
					</ListItem>
				)}
				renderInput={(params) => (
					<TextFieldWrapper
						placeholder={placeholder}
						sx={{
							maxHeight: size === MaterialUISizeEnum.SMALL
								? SMALL_VARIANT_INPUT_HEIGHT
								: undefined,
							fontSize: size === MaterialUISizeEnum.SMALL
								? SMALL_VARIANT_FONT_SIZE
								: undefined,
							borderRadius: MEDIUM_BORDER_RADIUS,
							backgroundColor: COLORS.white,
						}}
						{...params}
					/>
				)}
				sx={{
					maxHeight: size === MaterialUISizeEnum.SMALL
						? SMALL_VARIANT_INPUT_HEIGHT
						: undefined,
					fontSize: size === MaterialUISizeEnum.SMALL
						? SMALL_VARIANT_FONT_SIZE
						: undefined,
					borderRadius: MEDIUM_BORDER_RADIUS,
					backgroundColor: COLORS.white,
				}}
			/>
		</Box>
	);
};
